// TODO: требуется рефакторинг! пока заткнул через @ts-ignore и отключение правил линтера
import React from 'react';

import Layout from '@components/Layouts/Layout';
import { event } from '@analytics/gtm';
import SEO from '@components/Layouts/SEO';

import { DELIVERY_TYPE_PICKUP, DELIVERY_TYPE_COURIER } from '@periodica/consts';

import { DELIVERY_DEFAULT_CITY } from '@consts/deliveryConsts';

import { Select as SelectField } from '@periodica/ui-kit';
import { Switcher } from '@components/Blocks/Switcher';
import { Courier, Pickup, DeliveryBlock } from '@components/Delivery';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import type { CityType } from '@customTypes/delivery';
import { getDeliveryServices } from '../../periodicaApi/deliveryServices';
import { fetchCities, fetchPickupPointsByCity } from '../../periodicaApi/deliveryCities';

import styles from './Delivery.module.scss';

const switcherOptions = [
  {
    id: DELIVERY_TYPE_COURIER,
    title: 'Курьером',
  },
  {
    id: DELIVERY_TYPE_PICKUP,
    title: 'Самовывоз из ПВЗ',
  },
];

export function Head() {
  return (
    <>
      <SEO
        title="Доставка"
        description="Мы доставляем по России курьером до двери или в пункт самовывоза."
      />
      <YandexTableau />
    </>
  );
}

function DeliveryPage() {
  const [cities, setCities] = React.useState<CityType[]>([]);
  const [pickupCities, setPickupCities] = React.useState<CityType[]>([]);
  const [deliveryCities, setDeliveryCities] = React.useState<CityType[]>([]);
  const [deliveryInfo, setDeliveryInfo] = React.useState([]);
  const [deliveryType, setDeliveryType] = React.useState(DELIVERY_TYPE_COURIER);
  const [pickupPoints, setPickupPoints] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedCity, setSelectedCity] = React.useState<CityType>(DELIVERY_DEFAULT_CITY);

  const isPickupDelivery = deliveryType === DELIVERY_TYPE_PICKUP;

  const handleFetchCities = async () => {
    setIsLoading(true);
    try {
      const citiesData = await fetchCities();
      const deliveryCitiesData = citiesData.filter((city) => city.courier);
      const pickupCitiesData = citiesData.filter((city) => city.pickup);
      setPickupCities(pickupCitiesData);
      setDeliveryCities(deliveryCitiesData);
      setCities(citiesData);
    } catch (err) {
      throw new Error(`Could not fetch cities list: ${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeCity = (selectedCityId: string) => {
    const cityToSelect = cities.find((city) => city.id === Number(selectedCityId));
    // @ts-ignore
    setSelectedCity(cityToSelect);
  };

  const handleFetchDeliveryInfo = async () => {
    setIsLoading(true);
    try {
      const pickupPointsData = await fetchPickupPointsByCity(selectedCity.id);
      // @ts-ignore
      setPickupPoints(pickupPointsData);
      const deliveryData = await getDeliveryServices({
        cityId: selectedCity.id,
        isPickupDelivery,
      });
      setDeliveryInfo(deliveryData);
    } catch (err) {
      throw new Error(`Could not fetch delivery info: ${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeDeliveryType = (selectedDeliveryType: string) => {
    if (isLoading) {
      return;
    }
    setDeliveryType(selectedDeliveryType);
  };

  React.useEffect(() => {
    handleFetchCities();
  }, []);

  React.useEffect(() => {
    handleFetchDeliveryInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity.id, deliveryType]);

  return (
    <Layout>
      <div className={styles.wrapper}>
        <h1 className={styles.header}>Доставка</h1>
        <div className={styles.content}>
          <Switcher
            activeOption={deliveryType}
            options={switcherOptions}
            onSwitch={(value) => handleChangeDeliveryType(value)}
            customClassName={styles.switcher}
            disabled={!selectedCity.courier || !selectedCity.pickup}
          />
          <SelectField
            name="cityId"
            onChange={(city) => {
              event('event_site_dlv_pick_city');
              handleChangeCity(`${city}`);
            }}
            values={isPickupDelivery ? pickupCities : deliveryCities}
            value={selectedCity.id}
            label="Город доставки"
          />
          {deliveryType === DELIVERY_TYPE_COURIER ? (
            <Courier isLoading={isLoading} deliveryInfo={deliveryInfo} />
          ) : (
            <Pickup
              pickupPoints={pickupPoints}
              isLoading={isLoading}
              deliveryInfo={deliveryInfo}
              selectedCityId={selectedCity.id}
            />
          )}
          <DeliveryBlock title="Статус заказа">
            <p className={styles.noteText}>
              Мы собщаем о статусе вашего заказа, отправляя уведомления на email и телефон. Вам не
              придется беспокоиться.
            </p>
          </DeliveryBlock>
        </div>
      </div>
    </Layout>
  );
}

export default DeliveryPage;
